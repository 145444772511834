import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./Header.css";
import SideMenu from "./SideMenu";
import Backdrop from "./Backdrop";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/features/userSlice";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import SearchIcon from "@mui/icons-material/Search";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import PersonIcon from "@mui/icons-material/Person";
import getUserData from "../../utils/userDataService.js";

const Header = () => {
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sideMenu, setSideMenu] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const [search, setSearch] = useState(false);
  const [cartMenu, setCartMenu] = useState(false);
  const [balance, setBalance] = useState("");

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
  }, []);

  return (
    <>
      <header className="header">
        <div className="header-main">
          <div className="logo" onClick={() => navigate("/")}>
            <span>
              Dukebalmond<span className="blue">Credit</span>
            </span>
          </div>
          <ul>
            {user ? (
              <li
                className="text-white"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/user-dashboard")}
              >
                Dashboard
              </li>
            ) : (
              <li
                className="text-white"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/login")}
              >
                Login
              </li>
            )}
          </ul>
        </div>
      </header>
    </>
  );
};

export default Header;
